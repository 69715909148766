//@ts-nocheck
import React from "react"
import { withStyles, Grid, createStyles, Button, TextField, MenuItem } from "@material-ui/core"
import withDialogBox from "../../studio-store-ecommerce-components/src/HOC/withDialog.web"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { TextInput } from "../../admin-mobile-app/src/Utilities/Utils"
import { AddressValidation, PostalCodeValidation } from "../../admin-validations/src/validation"
import EditAddressController, { Props } from "./EditAddressController.web"
import withCountries from "../../studio-store-ecommerce-components/src/HOC/withCountires.web"
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withLoader.Web"

const configJSON = require("./config")

const Schema = Yup.object().shape({
  name: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(30, configJSON.maximum_30Characters),
  flat_no: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(10, configJSON.maximum_10Characters),
  address: AddressValidation.required(configJSON.thisFieldIsRequired),
  address_line_2: AddressValidation,
  zip_code: PostalCodeValidation("country"),
  phone_number: Yup.string(),
  city: Yup.string().required(configJSON.thisFieldIsRequired),
  address_state_id: Yup.string().required(configJSON.thisFieldIsRequired),
  country: Yup.string().required(configJSON.thisFieldIsRequired),
})

export class EditAddress extends EditAddressController {
  constructor(props: Props) {
    super(props);
  }

  closeDialog = () => {
    const { onCancel } = this.props;
    onCancel();
  };
  handleSubmit = (formData) => {
    this.props.onSubmit(formData);
  };

  handleStateCall = (event) => {
    const filterByCountryCode = this.props.countriesHOC?.find(country => country.attributes?.code === event);
    this.handleStateCalls(filterByCountryCode?.attributes?.id)
  }

  // function due to sonarQube
  handleCountryCode = (code: string) => {
    if (code === 'gb') {
      return 'uk';
    }
    if (code === 'in') {
      return 'india';
    } else {
      return code
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          name: this.props?.dataToPass?.delivery_addresses?.name
            ? this.props?.dataToPass?.delivery_addresses?.name
            : "",
          flat_no: this.props?.dataToPass?.delivery_addresses?.flat_no
            ? this.props?.dataToPass?.delivery_addresses?.flat_no
            : "",
          address: this.props?.dataToPass?.delivery_addresses?.address
            ? this.props?.dataToPass?.delivery_addresses?.address
            : "",
          address_line_2: this.props?.dataToPass?.delivery_addresses?.address
            ? this.props?.dataToPass?.delivery_addresses?.address
            : "",
          zip_code: this.props?.dataToPass?.delivery_addresses?.zip_code
            ? this.props?.dataToPass?.delivery_addresses?.zip_code
            : "",
          phone_number: this.props?.dataToPass?.delivery_addresses?.phone_number
            ? this.props?.dataToPass?.delivery_addresses?.phone_number
            : "",
          city: this.props?.dataToPass?.delivery_addresses?.city
            ? this.props?.dataToPass?.delivery_addresses?.city
            : "",
          address_state_id: this.props?.dataToPass?.delivery_addresses?.state
            ? this.props?.dataToPass?.delivery_addresses?.state : this.state.statePrefillId,

          country: this.props?.dataToPass?.delivery_addresses?.country === 'uk' ?
            'uk' :
            this.props?.dataToPass?.delivery_addresses?.country
          ,
          stateId: this.state.statePrefillId
        }}
        validationSchema={Schema}
        onSubmit={this.handleSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate style={{ margin: 0 }}>
              <Grid container className={classes.root} style={{ paddingLeft: 40 }}>
                <Grid item xs={10}>
                  <Grid container className="edit-address-container-grid">
                    <Grid item xs={10}>
                      <TextInput
                        name="name"
                        label="Name"
                        onBlur={handleBlur}
                        required
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <label className={classes?.label}>Country *</label>
                      <TextField
                        required
                        name="country"
                        placeholder="Enter the country"
                        select
                        InputProps={{
                          className: classes?.textField,
                        }}
                        fullWidth
                        variant="outlined"
                        value={values.country ?? ''}
                        onChange={handleChange}
                      >
                        {this.props.countriesHOC?.map((option: any) => (
                            <MenuItem
                              onClick={() => {
                                this.handleStateCall(option?.attributes?.code)
                              }}
                              value={this.handleCountryCode(option?.attributes?.code)}
                              key={+option?.id}
                            >
                              <span>
                                {this.props.countryToFlag(option.attributes?.code)}
                              </span> &nbsp; {option.attributes.name}
                            </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={10}>
                      <label className={classes?.label}>State *</label>
                      <TextField
                        required
                        placeholder="Select the state"
                        select
                        InputProps={{
                          className: classes?.textField,
                        }}
                        name="address_state_id"
                        value={values?.address_state_id?.length > 0 ? this.state.statePrefillId : values?.address_state_id ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                      >
                        {this.state.stateData?.map((option: any, stateIndex: number) => (
                            <MenuItem
                              key={+option?.id}
                              value={option?.attributes?.id}
                            >
                              {option.attributes.name}
                            </MenuItem>
                        ))}
                      </TextField>

                    </Grid>
                    <Grid item xs={10}>
                      <TextInput
                        name="city"
                        label="City"
                        required
                        onBlur={handleBlur}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextInput
                        name="flat_no"
                        label="Flat no"
                        onBlur={handleBlur}
                        required
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextInput
                        name="address"
                        label="Address"
                        onBlur={handleBlur}
                        required
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextInput
                        name="zip_code"
                        label="Zip code"
                        required
                        onBlur={handleBlur}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <label className={classes?.label}>Phone number</label>
                      <div className='d-flex align-items-center'>
                        <Grid xs={3}>
                          <TextField
                            required
                            name="country"
                            select
                            InputProps={{
                              className: classes?.textField,
                            }}
                            fullWidth
                            variant="outlined"
                            value={values.country ?? ''}
                            onChange={handleChange}
                            disabled
                          >
                            {this.props.countriesHOC?.map((option: any) => (
                              <MenuItem
                                key={+option?.id}
                                value={this.handleCountryCode(option?.attributes?.code)}
                              >
                                <span>
                                  {this.props.countryToFlag(option.attributes?.code)}
                                </span> &nbsp; {option.attributes.phone_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid xs={9}>
                          <TextInput
                            name="phone_number"
                            required
                            onBlur={handleBlur}
                            {...formikProps}
                          />
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <TextInput
                        name="address_line_2"
                        label="Address For"
                        onBlur={handleBlur}
                        required
                        {...formikProps}
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footerAction}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={this.closeDialog.bind(this)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "16px 48px", minWidth: "160px" }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export const styles = () =>
  createStyles({

    inputLabel: {
      fontWeight: 500,
    },
    footerAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: "0 0 1",
      gap: "16px",
      borderTop: "1px solid #E8E8E8",
    },
    root: {
      marginBottom: "48px",
    },
    textField: {
      width: '100%',
      height: 54,
      color: '#3C3E49',
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
    },
    label: {
      color: '#000000',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: '8px',
    }

  });
export default withStyles(styles)(withLoader(withDialogBox(withCountries(EditAddress))))
